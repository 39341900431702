import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React, { useState, useEffect } from "react"
import { graphql, PageProps, Link } from "gatsby"
import Layout from "../components/layout"
import { DiscoListQuery, DiscoListQuery_allMicrocmsDiscography_edges_node as Node } from "./__generated__/DiscoListQuery"
import StackGrid from "react-stack-grid";
import {Square} from "../components/utils"

export default ({ data, pageContext, location }: PageProps<DiscoListQuery, {}>) => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const w = window
    const d = document
    const e = d.documentElement
    const g = d.getElementsByTagName("body")[0]

    setWindowWidth(w.innerWidth || e.clientWidth || g.clientWidth);
  });

  const space = (isTitle = false) => {
    if (isTitle && windowWidth < 768) {
      return (
        <h2 className="block mt-10 mb-20 text-color-1 text-4xl text-center">
          Discography
        </h2>
      )
    }
    return (    
      <Square>
        <div className="flex bg-bg w-full h-full">
          {isTitle ?
            <h2 className="block m-auto text-color-1 text-3xl ">
              Discography
            </h2>
            : ''
          }
        </div>
      </Square>
    )
  };

  const contentItem = (item: Node) => {
    return <Link to={`/discography/${item.identity}`}>
      <Square>
        <div className="lg:p-0 md:p-5 w-full h-full ">
          <div className=" bg-contain w-full h-full "
            style={{
              backgroundImage: `url(${item.image.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            }}>
            <div className="transition duration-500 w-full h-full bg-black bg-opacity-0 lg:hover:bg-opacity-50 ">
              <div className="transition duration-500 flex w-full h-full opacity-0 lg:hover:opacity-100">
                <h2 className="block m-auto text-center text-color-default text-3xl ">
                  {item.title}
                </h2>
              </div>
            </div>
          </div >
          <h3 className="lg:hidden text-center text-xl h-20">
            {item.title}
          </h3>
        </div>
      </Square>
    </Link>

  }

  let list: JSX.Element[] = [];
  list = data.allMicrocmsDiscography.edges.map((e) => contentItem(e.node));
  list.splice(0, 0, space(true));

  return (
    <Layout
      seo={{
        title: "Discography",
      }}
      location={location}
    >
      <div className="md:m-0 m-10">
        <StackGrid
          columnWidth={
            windowWidth <= 1024 ? 
              windowWidth < 768 ? 
                '100%' :
                '50%' 
              : 
              '25%'
            }
          gutterWidth={0}
          gutterHeight={0}
        >
          {list}
        </StackGrid>
      </div>
     
    </Layout>
  )
}
export const query = graphql`
  query DiscoListQuery {
    allMicrocmsDiscography(sort: {fields: date, order: DESC}) {
      edges {
        node {
          identity
          title
          image {
            url
          }
        }
      }
    }
  }
`